import React from 'react';
import { Link } from 'gatsby';
import { Document, Page } from 'react-pdf/dist/entry.webpack';

import FavoriteForm from '../Favorite';
import { AuthUserContext } from '../Session';


class ItemList extends React.Component {
    constructor(props){
        super(props);
        this.data = Object.keys(props.array).map(i => props.array[i]);
        this.state = {
            array: this.props.array
        }

    }


    render(){
        return(
            <>
                <ul className="cards flexMe">
                {
                    this.state.array.map( (query ) => {
                    if('title' in query) {
                        const manufacturer = query.manufacturer;
                        const type = query.type;
                        const language = query.language;
                        var p = query.slug;
                        const pathname = p.replace('-', '_') ;
                        const title = query.title;
                        const uri = query.uri;
                        const fileURL = query.fileURL;

                        return (

                            <li>
                                <article className="card">
                                    <header>
                                        <Link to={uri}><h2>{query.title}</h2></Link>
                                        <AuthUserContext.Consumer>
                                          {authUser =>
                                            authUser ? (
                                                <><FavoriteForm language={language} type={type} manufacturer={manufacturer} pathname={pathname} slug={p} title={title} uri={uri} fileURL={fileURL} checkMe={authUser.favorites.hasOwnProperty(pathname) ? true : false } /></>
                                            ): (
                                                <></>
                                            )
                                        }
                                        </AuthUserContext.Consumer>
                                    </header>
                                    <ul>
                                        <li>
                                            <Document file={{ url: fileURL }}>
                                                <Page pageNumber={1} scale={0.25}/>
                                            </Document>
                                        </li>
                                        <li>Manufacturer: {manufacturer}</li>
                                        <li>Elevator Type: {type}</li>
                                        <li>Language: {language}</li>
                                    </ul>
                                    <footer>
                                        <Link className="button ghost" to={uri}>View Manual</Link>
                                    </footer>
                                </article>

                            </li>
                        )
                    }
                        return(null);
                    })
                }
                </ul>
            </>
        )
    }
}
export default ItemList;
